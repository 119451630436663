<script setup lang="ts">
    import {
        type AuthenticationAction,
        type AuthenticationQuery,
        useAuthenticationAction,
        useAuthenticationQuery,
    } from '@/composables/authentication';
    import { object, string } from 'zod';
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/zod';
    import FormField from '@/components/form-components/FormField.vue';
    import { hasValidationError } from '@/util/hasValidationError';
    import Button from 'primevue/button';
    import InputText from 'primevue/inputtext';
    import { useI18n } from 'vue-i18n';
    import { stringValueOfParam } from '@/util/param';
    import { useRoute } from 'vue-router';
    import { ref } from 'vue';
    import ErrorTag from '@/components/ErrorTag.vue';
    import { Navigation } from '@/router/navigation';
    import router from '@/router';
    import { getLogger } from '@/logger/logger';

    const { t } = useI18n();
    const logger = getLogger('ForgotPasswordView');
    const schema = object({
        email: string().email(t('ERROR.INVALID_EMAIL')),
    });
    const showError = ref(false);

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [email] = defineField('email');

    const authenticationQuery: AuthenticationQuery = useAuthenticationQuery();
    const authenticationAction: AuthenticationAction = useAuthenticationAction();
    const language = stringValueOfParam(useRoute().params.language);

    const isUserLoggedIn = authenticationQuery.isUserLoggedIn;

    const onSubmit = handleSubmit(async (values) => {
        try {
            showError.value = false;
            await authenticationAction.requestPasswordReset(values.email, language);
            await router.push({ name: Navigation.PasswordForgotSuccess });
        } catch (error) {
            logger.error(error, 'Requesting password reset token failed');
            showError.value = true;
        }
    });
</script>

<template>
    <div v-if="!isUserLoggedIn" class="flex-container">
        <h2>{{ t('LOGIN.FORGOT_PASSWORD.TITLE') }}</h2>
        <p class="text">{{ t('LOGIN.FORGOT_PASSWORD.DESCRIPTION') }}</p>
        <form class="container" @submit.prevent="onSubmit">
            <FormField :label="t('LOGIN.EMAIL')" :error-message="errors.email">
                <InputText
                    v-model="email"
                    class="full-width"
                    :invalid="hasValidationError(errors.email)"
                    :class="showError ? 'error-border' : ''"
                />
            </FormField>
            <ErrorTag v-if="showError" :message="t('LOGIN.RESET_PASSWORD_REQUEST.ERROR_SUMMARY')" />
            <div>
                <Button type="submit" class="button">{{ t('LOGIN.FORGOT_PASSWORD.REQUEST_PASSWORD_RESET') }}</Button>
            </div>
        </form>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .flex-container {
        margin: auto;
        display: flex;
        flex-direction: column;
        background: main.$color-white;
        padding: main.$spacing-6;
        max-width: 448px;

        :deep(.error-border) {
            border-color: main.$color-error-red;
        }
    }

    .text {
        margin-bottom: 0;
    }

    .container {
        gap: main.$spacing-6;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
</style>
